import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import './demo.css'; 
import botlos from '../Assets/logo-chat.png';
import { Link } from 'react-router-dom';

const ChatBotDemo = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false); 
  const chatBoxRef = useRef(null);

  const questionsAndAnswers = useMemo(() => 
    [
      {
        question: "How do I report a cyber crime?",
        answer: "1. Gather Evidence: Collect all relevant evidence, including screenshots, emails, messages, or any other digital proof.\n2. File an FIR: Go to the nearest police station and file a First Information Report (FIR) under the Indian Penal Code (IPC) or the Information Technology (IT) Act, 2000. You can also file an online complaint through the National Cyber Crime Reporting Portal (cybercrime.gov.in).\n3. Contact Cyber Cell: Reach out to the cybercrime division of your state's police. Many states have dedicated cybercrime cells.\n4. Provide Details: Give a detailed account of the incident, including dates, times, and descriptions of the offense.\n5. Follow-Up: Obtain a copy of the FIR or acknowledgment of your complaint and follow up regularly for updates.\n6. Seek Legal Assistance: If necessary, consult a lawyer specializing in cyber law.\n7. For immediate assistance, you can contact the Cyber Crime Helpline Number: 155260."
      },
      {
        question: "My client, an employee, reports sexual harassment by a colleague and seeks legal recourse.",
        answer: "1. Document all incidents meticulously, including dates, times, locations, and witnesses.\n2. Report the harassment to HR or the internal complaints committee.\n3. If internal remedies fail, file a complaint with the National Commission for Women or the State Women’s Commission.\n4. Explore legal options under the Sexual Harassment of Women at Workplace Act, 2013, and relevant IPC sections.\n5. File a police complaint or FIR if necessary.\n6. Monitor the employer's response and advise on further action if they fail to act.\n7. Encourage your client to seek emotional support from trusted friends, family, or mental health professionals."
      },
      {
        question: "A client is involved in a dispute over the terms of a business contract.",
        answer: "1. Review the contract terms and analyze the specific clauses in dispute, especially regarding payment schedules and deliverables.\n2. Assess the client's interpretation of the contract and compare it with the other party's claims.\n3. Determine whether the other party is not fulfilling their contractual obligations.\n4. Seek legal assistance to resolve the dispute through negotiation or legal action.\n5. Ensure the enforcement of the contract terms as understood by the client."
      },
      {
        question: "What are the steps involved in filing an appeal?",
        answer: "1. Determine if the decision is appealable according to applicable laws.\n2. Consult a lawyer specializing in appellate practice for guidance.\n3. File a notice of appeal with the trial court, specifying the judgment or order being appealed, and serve it to the other party.\n4. Compile the trial record, including all relevant documents and transcripts.\n5. Draft and file the appellant's brief outlining the grounds for the appeal and legal arguments.\n6. Present oral arguments before the appellate court if applicable.\n7. Wait for the appellate court to issue a decision and consider further actions if the appeal is unsuccessful."
      },
      {
        question: "How to interpret and apply ambiguous or outdated laws?",
        answer: "1. Research the legislative intent behind the law using historical documents.\n2. Analyze the law in the context of the entire statute to ensure harmonious interpretation of provisions.\n3. Refer to judicial precedents to understand how the law has been historically applied.\n4. Use legal principles like ejusdem generis and noscitur a sociis to resolve ambiguities.\n5. Consider subsequent amendments or repeals to infer changes in legislative intent.\n6. Consult legal experts for insights on outdated laws.\n7. Advocate for legislative amendments if the law no longer serves its intended purpose."
      },
      {
        question: "A key witness is unreliable or has credibility issues.",
        answer: "1. Evaluate the witness’s reliability, considering their history of dishonesty, personal biases, or inconsistent statements.\n2. Prepare for the opposing party's challenge to the witness's credibility in court.\n3. Consider cross-examination strategies to address the witness's credibility issues.\n4. Assess whether the witness’s testimony is critical to the case.\n5. If necessary, seek alternative witnesses or corroborating evidence."
      },
      {
        question: "How to handle and present digital evidence in court?",
        answer: "1. Maintain a clear chain of custody to prove the evidence has not been tampered with.\n2. Collect digital evidence using forensically sound methods to preserve its integrity.\n3. Thoroughly document the collection process, including timestamps and methods used.\n4. Engage digital forensic experts to analyze and explain the evidence.\n5. Present the evidence in appropriate formats (e.g., printouts, digital displays).\n6. Ensure proper authentication of the evidence before presenting it.\n7. Clearly demonstrate the relevance of the digital evidence to the case.\n8. Maintain backup copies of the evidence to prevent data loss."
      }
    ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentQuestionIndex < questionsAndAnswers.length) {
        setMessages(prevMessages => {
          const newMessages = [...prevMessages, { text: questionsAndAnswers[currentQuestionIndex].question, isBot: true }];

          setLoading(true);

          setTimeout(() => {
            setMessages(prevMessages => [
              ...newMessages,
              { text: questionsAndAnswers[currentQuestionIndex].answer, isBot: false }
            ]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setLoading(false); 
          }, 2000);

          return newMessages; 
        });
      }
    }, 2100);

    return () => clearInterval(interval);
  }, [currentQuestionIndex, questionsAndAnswers]); 

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className='container-fluid'>
      <div className='row  headerchatbot'>
      <div className='col-4 col-lg-6 col-md-6 col-sm-4 '><Link to="/"><button type='button' className=' demobackbtn '><FontAwesomeIcon icon={faArrowLeft}/></button></Link></div>
        <div className=' d-flex justify-content-center col-8 col-lg-6 col-md-6 col-sm-8'><button type='button' className='btn btn-warning demobtn text-dark'>Scenario Solver Demo</button></div>
      </div>
    <div className='chatdemo-container mt-2'>
      <div ref={chatBoxRef} className='chat-box'>
        {messages.map((message, index) => (
          <div
            key={index}
            className={message.isBot ? 'bot-message' : 'user-message'}
          >
            {message.isBot ? (
              <>
                <pre className='preS'>{message.text}</pre>
                
              </>
            ) : (
              <>
                <img src={botlos} alt="User Logo" className='user-logo' />
                <pre className='preS'>{message.text}</pre>
              </>
            )}
          </div>
        ))}
        {loading && (
          <div className=" loadings ">
            <FontAwesomeIcon icon={faEllipsis} beat />
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default ChatBotDemo;
