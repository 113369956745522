import React, { useState, useEffect, useRef, useMemo } from 'react';

import './demo.css'; 
import axios from 'axios';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import botlos from '../Assets/logo-chat.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const JudgementDemo = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false); 
  const chatBoxRef = useRef(null);

  const questionsAndAnswers = useMemo(() => [
    {
      question: "Can you provide relevant judgments for a robbery case?",
      answer: "Ravi Kumar vs State (Govt. of NCT of Delhi)\nCrl. A. No. 309/2018\n(Delhi High Court)\nJudgment Date: 07-02-2023\nRobbery conviction upheld; insufficient evidence for injury on the hand."
    },
    {
      question: "I NEED Judgement copy of Ravi Kumar vs State (Govt. of NCT of Delhi)",
      answer: {
        filename: "Ravi_Kumar_vs_State_(Govt._of_NCT_of_Delhi)_processed.pdf",
      }  
    },
    {
      question: "Please provide relevant judgments for POCSO cases.",
      answer: "Case Title: State of Haryana v. Bhupender @ Bhuppi\nCourt: Supreme Court of India\nCase No.: Criminal Appeal No. 1138 of 2023\nJudgment Date: 19-05-2023\n\nThe Supreme Court upheld the conviction of the appellant under the POCSO Act, emphasizing the need for stringent action against child sexual abuse. The Court found no reason to interfere with the High Court's judgment."
    },
    {
      question: "What is the judgment in the Nirbhaya case?",
      answer: "The Supreme Court of India delivered its judgment on the Nirbhaya case on May 5, 2017. The five adult convicts were sentenced to death for gang rape and murder. The court emphasized the brutality of the crime and the need for justice for the victim and society."
    },
    {
      question: "Judgement for Keshavananda Bharathi vs state of Kerala",
      answer: "Keshavananda Bharathi Sr. Vs. State of Kerala & Anr. 1973 AIR 1461\nThe Supreme Court held that Parliament has no power to alter the basic structure of the Constitution and that the power to amend the Constitution does not include the power to alter its basic structure. This landmark judgment established the 'basic structure doctrine,' asserting that certain fundamental features of the Constitution are inviolable and cannot be amended."
    },
    {
      question: "Relevant case against Indian constitution",
      answer: "In the Minerva Mills Ltd. and Ors. Vs. Union of India and Ors. case, the Supreme Court reaffirmed the basic structure doctrine established in the Keshavananda Bharati case. The Court struck down amendments to the Constitution that violated the basic structure, emphasizing the supremacy of the Constitution and the need to protect its fundamental principles."
    },
    {
      question: "Judgement for Golaknath vs State of Punjab",
      answer: "In the Golaknath case (1967), the Supreme Court ruled that Parliament could not curtail any of the Fundamental Rights in the Constitution. The Court held that the Parliament had no authority to amend Part III of the Constitution, which contains the Fundamental Rights. This decision laid the foundation for the future 'basic structure doctrine'."
    },
    {
      question: "Judgement for Maneka Gandhi vs Union of India",
      answer: "In the Maneka Gandhi case (1978), the Supreme Court expanded the interpretation of Article 21, ruling that the right to life and personal liberty must be interpreted broadly. The case established that any law interfering with personal liberty must be just, fair, and reasonable, significantly broadening the scope of fundamental rights."
    }
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentQuestionIndex < questionsAndAnswers.length) {
        setMessages(prevMessages => {
          const question = questionsAndAnswers[currentQuestionIndex].question;
          const answer = questionsAndAnswers[currentQuestionIndex].answer;

          const newMessages = [...prevMessages, { text: question, isBot: true }];

          setLoading(true);

          setTimeout(() => {
            if (typeof answer === "string") {
              setMessages(prevMessages => [
                ...newMessages,
                { text: answer, isBot: false }
              ]);
            } else if (answer && answer.filename) {
              setMessages(prevMessages => [
                ...newMessages,
                {  isBot: false, filename: answer.filename }
              ]);
            }

            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setLoading(false);
          }, 2000);

          return newMessages;
        });
      }
    }, 2100);

    return () => clearInterval(interval);
  }, [currentQuestionIndex, questionsAndAnswers]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleDownload = async (filename) => {
    try {
      setLoading(true);

      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZVB6D4DGP", 
          secretAccessKey: "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", 
        },
      });

      const command = new GetObjectCommand({
        Bucket: "jurist-bot",
        Key: `Relevant_judgements/${filename}`,
      });

      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });

      const response = await axios.get(url, { responseType: "blob" });

      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row  headerchatbot '>
      <div className='col-4 col-lg-6 col-md-6 col-sm-4 '><Link to="/"><button type='button' className=' demobackbtn '><FontAwesomeIcon icon={faArrowLeft}/></button></Link></div>
      <div className=' d-flex justify-content-center col-8 col-lg-6 col-md-6 col-sm-8'><button type='button' className='btn btn-warning demobtn text-dark'>Relvent Judgment Demo</button></div>      </div>
      <div className='chatdemo-container mt-2'>
        <div ref={chatBoxRef} className='chat-box'>
          {messages.map((message, index) => (
            <div
              key={index}
              className={message.isBot ? 'bot-message' : 'user-message'}
            >
                {message.isBot ? (
              <>
                <pre className='preS'>{message.text}</pre>
                
              </>
            ) : (
              <>
                <img src={botlos} alt="User Logo" className='user-logo' />
                <pre className='preS'>{message.text}</pre>
              </>
            )}
              {message.filename && (
                <button onClick={() => handleDownload(message.filename)} className="download-button mt-0 demobtn">
                  Download Relevant Judgement copy
                </button>
              )}
            </div>
          ))}
          {loading && (
            <div className="loading-indicator loadings">
              <FontAwesomeIcon icon={faEllipsis} beat />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JudgementDemo;
