const config = {
    apiBaseUrl: "https://api.juristbot.ai"
  };
  
  export default config;

  // const config = {
  //   apiBaseUrl: "http://localhost:3005"
  // };
  
  // export default config;