import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./authotp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faKey } from "@fortawesome/free-solid-svg-icons";
import config from '../config/config';

function Authenticateotp() {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(`${config.apiBaseUrl}/verifyotp`, {
        otp,
      });
      console.log(response.data);
      toast.success("OTP verified successfully!");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
      toast.error("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid backimage5">
      <ToastContainer />
      <div className="otp-authicatediv">
        <form onSubmit={handleLogin}>
          <h4 className="authotitletxt">Enter the OTP</h4>
          <div className="mt-4">
          <div className="input-container">
          <FontAwesomeIcon icon={faKey} className="loginfaicons"/>
            <input
              type="text"
              className="reg-form"
              placeholder="Enter your OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            /></div>
          </div>
          <button
            className="autoticatebtn mt-3"
            type="submit"
            disabled={loading}
          >
            {loading ? "Verifying..." : "Continue"}
          </button>
          {error && <p className="text-danger text-center">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Authenticateotp;
