import React, { useState, useRef, useEffect } from "react";
import bgImage from "./assetes/images/bg_image_1.jpg";
import bgImageSub from "./assetes/images/bg_image_2.jpg";
import swiperHead from "./assetes/images/swiper_head.svg";
import absteact from "./assetes/images/absteact.svg";
import logoImage from "./assetes/images/logo_img_1.png";
import robot from "./assetes/images/robot.gif";
import "./Landing.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PricingOptions from "./page/price-selection.js";
import ContactForm from "./page/send-email-template.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBars } from "@fortawesome/free-solid-svg-icons";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import send from '../Landingpage/assetes/images/send.svg'

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("#home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false); 
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
    className="navbar navbar-expand-lg navbar-dark bg-dark nav-bar-cls"
    style={{ borderRadius: isMenuOpen ? "20px" : "58px" }} 
  >
    <div className="container-fluid">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-9 d-flex justify-content-start">
        <img src={logoImage} alt="logo" className="landinglogo" />
      </div>
      <FontAwesomeIcon
        className="col-xl-9 col-lg-8 col-md-7 col-sm-6 col-3 navbar-toggler navbar-toggler_2"
        type="button"
        onClick={toggleMenu}
        aria-controls="navbarNav"
        aria-expanded={isMenuOpen}
        aria-label="Toggle navigation"
        icon={faBars}
        style={{ width: "52px", padding: "0px", margin: "0px" }}
      />
      <div
    
        className={`collapse navbar-collapse ${isMenuOpen ? "nav-bar-box show" : ""}`}
        id="navbarNav"
      >
        <ul   className="navbar-nav" style={{ margin: "auto" }}>
          <li className="nav-item nav-cls">
            <a
              className={`nav-link ${activeLink === "#home" ? "active" : ""}`}
              href="#home"
              onClick={() => handleClick("#home")}
            >
              Home
            </a>
          </li>
          <li className="nav-item nav-cls">
            <a
              className={`nav-link ${activeLink === "#about" ? "active" : ""}`}
              href="#about"
              onClick={() => handleClick("#about")}
            >
              About
            </a>
          </li>
          <li className="nav-item nav-cls">
            <a
              className={`nav-link ${activeLink === "#services" ? "active" : ""}`}
              href="#services"
              onClick={() => handleClick("#services")}
            >
              Our services
            </a>
          </li>
          <li className="nav-item nav-cls">
            <a
              className={`nav-link ${activeLink === "#pricing" ? "active" : ""}`}
              href="#pricing"
              onClick={() => handleClick("#pricing")}
            >
              Pricing
            </a>
          </li>
        </ul>
        <div className="col-lg-3 col-md-3 col-sm-3 col-4 massnav">
          <Link to="/login"><button type="button" className="juristbotAIbtn">
            Jurist bot AI
          </button></Link>
        </div>
      </div>
    </div>
  </nav>
  
  );
};

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,  
    });
  }, []);
  const [activeLink, setActiveLink] = useState("#home");

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    membershipRef.current.style.display = "none";

    setSelectedOption(option);
  };

  const membershipRef = useRef(null);

  useEffect(() => {
    if (membershipRef.current) {
      membershipRef.current.style.display = "block";
    }
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="custom-left-arrow"
        style={{
          position: "absolute",
          left: "10px",
          zIndex: 100,
          backgroundColor: "rgb(34 34 34)",
          color: "#ffd119",
          border: "none",
          borderRadius: "50%",
          cursor: "pointer",
          padding: "10px 15px",
          width: "44px",
        }}
      >
        ←
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="custom-right-arrow"
        style={{
          position: "absolute",
          right: "10px",
          zIndex: 100,
          backgroundColor: "rgb(34 34 34)",
          color: "#ffd119",
          border: "none",
          borderRadius: "50%",
          cursor: "pointer",
          padding: "10px 15px",
          width: "44px",
        }}
      >
        →
      </button>
    );
  };

  const CustomLeftArrowSlider = ({ onClick, carouselState }) => {
    const { currentSlide } = carouselState;
    return (
      <button
        onClick={onClick}
        className="custom-left-arrow"
        disabled={currentSlide === 0} 
        style={{
          position: "absolute",
          right: "42px",
          top: "0px",
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          border: "none",
          width: "42px",
          borderRadius: "50%",
          cursor: "pointer", 
          padding: "10px",
          marginRight: "15px",
          opacity: currentSlide === 0 ? 0.5 : 1, 
          pointerEvents: currentSlide === 0 ? "none" : "auto", 
        }}
      >
        ←
      </button>
    );
  };

  const CustomRightArrowSlider = ({ onClick, carouselState }) => {
    const { currentSlide, totalItems, slidesToShow } = carouselState;
    const isLastSlide = currentSlide + slidesToShow >= totalItems;
    return (
      <button
        onClick={onClick}
        className="custom-right-arrow"
        disabled={isLastSlide} 
        style={{
          position: "absolute",
          right: "0",
          top: "0",
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "white",
          border: "none",
          borderRadius: "50%",
          cursor: "pointer", 
          padding: "10px",
          width: "42px",
          opacity: isLastSlide ? 0.5 : 1, 
          pointerEvents: isLastSlide ? "none" : "auto", 
        }}
      >
        →
      </button>
    );
  };



  const handleClick = (href) => {
    setActiveLink(href);
  };

  return (
    <div className="main-container">
      {/* Hero Section */}
      <section
        id="home"
        className="col-12 text-white text-center d-flex align-items-center justify-content-start flex-column"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          margin: "0px",
          backgroundImage: `url(${bgImage})`,
          backgroundColor: "#ffffff !important",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Navbar />

        <div
        data-aos="fade-up"
          className="col-sm-10 col-md-10 col-lg-6 mt-5 heropage"
          style={{
            padding: "42px 10px",
            paddingTop: "122px"
          }}
        >
          <h1
            className="display-4 font-family"
            style={{
              color: "black",
              fontWeight: "700",
              fontSize: "52px",
            }}
          >
            Welcome to Jurist Bot{" "}
            <span
              style={{
                color: "#BCBCBC",
              }}
            >
              {" "}
              your AI legal assistant counsel
            </span>
          </h1>
          <p
            className="hero-para"
            style={{ color: "black", marginLeft: "auto", marginRight: "auto" }}
          >
            Enhance your legal journey with state-of-the-art AI technology.
            Juristbot AI is your dependable AI Assistant lawyer, ready to offer
            expert advice and support. Experience unparalleled legal assistance
            with efficiency and precision.
          </p>
          <Link to="/login"><button type="button" className="juristbotAIbutton">
       Try for juristbot AI
          </button></Link>
        </div>
        <div className="static-info"  data-aos="fade-up">
          <h6
            className="statichead col-8">
            Unlock 1 Month Free - Only for the First 2000 Members!
          </h6>
          <div className=" col-4  padd-cls" >
          <Link to="/register"><button className="jurist-btn " type="button" >
              Register now <img className="send" src={send} alt="send"/>     
            </button></Link>
          </div>
        </div>
      </section>
{/* About */}
      <section
        id="about"
        style={{
          backgroundImage: `url(${bgImageSub})`,
          minHeight: "352px",
        }}
        className="pt-2"
      >
        <div className="container">
          <div className="row text-center">
            <div
           data-aos="zoom-in-down"
              className="col-lg-7 col-md-7 col-sm-10 col-12 order-lg-1 order-md-1  order-sm-2 order-2 mt-5 mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h6
                className="font-family "
                style={{
                  fontWeight: "700",
                }}
              >
                <img
                  src={absteact}
                  alt="logo"
                  style={{
                    height: "25px",
                    marginRight: "8px",
                  }}
                />{" "}
                About
              </h6>
              <h5
                className="font-family "
                style={{
                  fontSize: "42px",
                  fontWeight: "600",
                }}
              >
                Our{" "}
                <span
                  style={{
                    color: "#FFD119",
                  }}
                >
                  Journey
                </span>
              </h5>
              <p
                className="font-family about-class"
                style={{
                  textAlign: "left",
                  color: "#858585",
                }}
              >
                <span
                  style={{
                    color: "#FFD119",
                    marginRight:"10PX",
                    fontSize:"18PX",
                  }}
                >
                  Juristbot
                </span>
                 Revolutionizes legal assistance with AI-driven solutions,
                empowering the public, police, advocates, and judges. It offers
                accessible legal knowledge, expert case guidance, and judicial
                insights, enhancing decision-making at all levels. Committed to
                excellence, Juristbot transforms the legal profession with
                precise, reliable, and comprehensive support.
              </p>
            </div>
            <div  data-aos="flip-left"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="700"  className=" col-lg-5 col-md-5 col-sm-12 col-12 order-lg-2 order-md-2  order-sm-1 order-1">
            <Link to="/login">  <img  className="roboatimg"  src={robot} alt="robot" /></Link>
            </div>
          </div>
        </div>
      </section>

      <section
        id="services"
        style={{
          backgroundImage: `url(${bgImageSub})`,
          minHeight: "352px",
        }}
        className="py-5"
      >
        <div className="container">
          <div className="row text-center">
            <div
            data-aos="zoom-in"
              className="col-sm-10 col-md-12 col-lg-12"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "45px",
              }}
            >
              <h6
                className="font-family"
                style={{
                  fontWeight: "700",
                }}
              >
                <img
                  src={absteact}
                  alt='abstract'
                  style={{
                    height: "25px",
                    marginRight: "8px",
                  }}
                />{" "}
                Our services
              </h6>
              <h5
                className="font-family"
                style={{
                  fontSize: "42px",
                  fontWeight: "600",
                }}
              >
                Our{" "}
                <span
                  style={{
                    color: "#FFD119",
                  }}
                >
                  Features
                </span>
              </h5>
              <p
                className="font-family about-class"
                style={{
                  textAlign: "left",
                  color: "#858585",
                }}
              >
                Juristbot streamlines legal drafting, judgment references,
                scenario analysis, cross-questioning, compliance checks, and
                client management—enhancing efficiency, accuracy, and client
                relations with AI-powered legal tools tailored to Indian law.
              </p>
            </div>
            <div data-aos="zoom-in-down" className="col-12 d-flex feature-style">
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    padding: "0px 5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "30px",
                      borderRadius: "10px",
                      color: "white",
                      background: "#222222",
                      minHeight: "221px",
                    }}
                  >
                    <h6
                      className="font-family"
                      style={{
                        marginBottom: "28px",
                        textAlign: "left",
                      }}
                    >
                     Scenario solver
                    </h6>
                    <p
                      className="font-family about-class"
                      style={{
                        textAlign: "left",
                      }}
                    >
                     Harness AI-powered solutions to tackle intricate legal
                      challenges, providing data-driven insights and expert
                      recommendations for informed decision-making, streamlining
                      complex legal processes with accuracy and efficiency.
                    </p>
                    <div className=" d-flex justify-content-end">
                      <Link to="/scenario-solver-Demo"><button className="demo-btn "> Scenario solver Demo </button></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    padding: "0px 5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "30px",
                      borderRadius: "10px",
                      color: "white",
                      background: "#222222",
                      minHeight: "221px",
                    }}
                  >
                    <h6
                      className="font-family"
                      style={{
                        marginBottom: "28px",
                        textAlign: "left",
                      }}
                    >Legal drafting
                      
                    </h6>
                    <p
                      className="font-family about-class"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      
                      Instantly create precise legal drafts tailored to your
                      unique requirements. Streamline your legal processes with
                      quick, customized documents, saving time and ensuring
                      accuracy with every draft.
                    </p>
                    <div className=" d-flex justify-content-end">
                      <Link to="/Leal-draft-Demo"><button className="demo-btn ">Legaldraft Demo </button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-down" className="col-12 d-flex feature-style">
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    padding: "0px 5px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        border: "1px solid black",
                        padding: "30px",
                        borderRadius: "10px",
                        color: "white",
                        background: "#222222",
                        minHeight: "221px",
                      }}
                    >
                      <h6
                        className="font-family"
                        style={{
                          marginBottom: "28px",
                          textAlign: "left",
                        }}
                      >
                        Cross Q/A
                      </h6>
                      <p
                        className="font-family about-class"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Enhance your case preparation with targeted
                        cross-questions and counter-questions. Sharpen your
                        arguments, anticipate opposing points, and build a
                        stronger case by addressing potential weaknesses and
                        contradictions effectively.
                      </p>
                      <div className=" d-flex justify-content-end">
                      <Link to="/Cross-Demo"><button className="demo-btn "> CrossQussion Demo</button></Link>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{
                  paddingTop: "12px",
                }}
              >
                <div
                  style={{
                    padding: "0px 5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "30px",
                      borderRadius: "10px",
                      color: "white",
                      background: "#222222",
                      minHeight: "221px",
                    }}
                  >
                    <h6
                      className="font-family"
                      style={{
                        marginBottom: "28px",
                        textAlign: "left",
                      }}
                    >
                      Relevant judgement
                    </h6>
                    <p
                      className="font-family about-class"
                      style={{
                        textAlign: "left",
                      }}
                    >
                    Effortlessly explore a vast database of relevant judgments tailored to your cases,  streamlining your legal research, and enhancing case preparation efficiency. Empower your practice with comprehensive insights at your fingertips, saving you valuable time and resources.
                    </p>
                    <div className=" d-flex justify-content-end">
                      <Link to="/Relevent-judgement-Demo"><button className="demo-btn ">Relevant judgement Demo  </button></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
            data-aos="zoom-out-right"
              style={{  display: "flex",  flexDirection: "column",  alignItems: "flex-start", marginBottom: "62px",  marginTop: "62px", }}
            >
              <h6 className="font-family "  style={{  fontWeight: "700",  }} >
                <img
                  src={absteact}
                  alt="absteact"
                  style={{
                    height: "25px",
                    marginRight: "8px",
                  }}
                />
                Testimonials
              </h6>
              <h5
                className="font-family "
                style={{
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                Our{" "}
                <span
                  style={{
                    color: "#FFD119",
                  }}
                >
                  Testimonials
                </span>
              </h5>
              <p
                className="font-family about-class"
                style={{
                  textAlign: "left",
                  color: "#858585",
                }}
              >
                Discover how Juristbot is revolutionizing the legal world - hear
                from clients who trust our AI-driven solutions for unmatched
                efficiency, accuracy, and game-changing results.
              </p>
            </div>

            <div style={{ position: "relative", marginBottom: "42px" }}>
     
       
              <Carousel
                responsive={responsive}
                autoPlay={true}
                 autoPlaySpeed={3000} 
                infinite={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
              >
                <div
                 data-aos="fade-down"
                data-aos-easing="linear"
                  style={{
                    padding: "5px 30px",
                  }}
                >
                  <img
                    src={swiperHead}
                    style={{
                      width: "75%",
                      marginBottom: "22px",
                    }}
                    alt="head"
                  />
                  <p className="min-hgt-swipper swiper-pstyle about-class">
                    I’m working as a advocate for past 10 years. For me drafting
                    the one case it well take 5 to 6 hours. After using jurist
                    bot Ai I can easily draft my case within half an hours so it
                    will save my time
                  </p>
                  <p
                    style={{
                      color: "rgb(255, 209, 25)",
                    }}
                  >
                    Arun prasath
                  </p>
                </div>
                <div
                  style={{
                    padding: "5px 30px",
                  }}
                >
                  <img
                    src={swiperHead}
                    style={{
                      width: "75%",
                      marginBottom: "22px",
                    }}
                    alt="head"
                  />
                  <p className="min-hgt-swipper swiper-pstyle about-class">
                  Jurist Bot AI has significantly improved the accuracy of my legal documents. The ability to quickly revise and adapt content makes it a valuable asset in my practice.
                  </p>
                  <p
                    style={{
                      color: "rgb(255, 209, 25)",
                    }}
                  >
                    Hari Baskar
                  </p>
                </div>
                <div
                  style={{
                    padding: "5px 30px",
                  }}
                >
                  <img
                    src={swiperHead}
                    style={{
                      width: "75%",
                      marginBottom: "22px",
                    }}
                    alt="head"
                  />
                  <p className="min-hgt-swipper swiper-pstyle about-class">
                  This tool has been incredibly helpful in ensuring that my legal documents are both thorough and clear. The revision process is straightforward and efficient, making my job much easier.
                  </p>
                  <p
                    style={{
                      color: "rgb(255, 209, 25)",
                    }}
                  >
                    Gowtham Naren
                  </p>
                </div>
                <div
                  style={{
                    padding: "5px 30px",
                  }}
                >
                  <img
                    src={swiperHead}
                    style={{
                      width: "75%",
                      marginBottom: "22px",
                    }}
                    alt="head"
                  />
                  <p className="min-hgt-swipper swiper-pstyle about-class">
                    Jurist bot Ai provides excellent support and flexibility,
                    with features that allow for quick modifications and
                    updates. revisions are incredibly helpful in ensuring that
                    the final documents are comprehensive and clear.
                  </p>
                  <p
                    style={{
                      color: "rgb(255, 209, 25)",
                    }}
                  >
                    Vishnu prasath
                  </p>
                </div>
                <div
                  style={{
                    padding: "5px 30px",
                  }}
                >
                  <img
                    src={swiperHead}
                    style={{
                      width: "75%",
                      marginBottom: "22px",
                    }}
                    alt="head"
                  />
                  <p className="min-hgt-swipper swiper-pstyle about-class">
                  The flexibility of Jurist Bot AI is impressive. It adapts to different legal needs seamlessly and ensures that all documents are both comprehensive and clear. It’s become an essential tool in my practice.
                  </p>
                  <p
                    style={{
                      color: "rgb(255, 209, 25)",
                    }}
                  >
                    Shree Nithiya
                  </p>
                </div>
              </Carousel>
           
            </div>
            <div
           data-aos="fade-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "62px",
                borderBottom: "1px solid #e8e5e5",
                paddingBottom: "62px",
              }}
            >
              <h6
                className="font-family "
                style={{
                  fontWeight: "700",
                }}
              >
                <img
                  src={absteact}
                  alt="absteact"
                  style={{
                    height: "25px",
                    marginRight: "8px",
                  }}
                />{" "}
                FAQ
              </h6>
              <h5
                className="font-family "
                style={{
                  fontSize: "42px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Frequently{" "}
                <span
                  style={{
                    color: "#FFD119",
                  }}
                >
                  Asked Questions
                </span>
              </h5>
              <p
                className="font-family about-class "
                style={{
                  textAlign: "left",
                  color: "#858585",
                }}
              >
                Get quick answers to the most common questions about JuristBot.
                From legal drafting to complex case handling, explore how
                JuristBot can assist you in your legal journey. If you don't
                find what you're looking for, feel free to reach out to our
                support team!
              </p>
            </div>
            <div>
              <div className="swipe-slider ">
                1
                <span
                  style={{
                    color: "gray",
                  }}
                >
                  of 10
                </span>
              </div>
              <div
                style={{
                  position: "relative",
                  marginTop: "20px",
                  marginBottom: "1px solid #e7e5e5",
                }}
              >
                <Carousel
                  responsive={responsive} 
                  customLeftArrow={<CustomLeftArrowSlider />}
                  customRightArrow={<CustomRightArrowSlider />}
                  infinite={false} 
                >
                  <div  data-aos="fade-up" style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        Who can use Juristbot?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                            Juristbot is useful for lawyers, judges, police
                            officers, and anyone seeking legal information or
                            assistance.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        How does Juristbot help with legal drafting?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                            Juristbot generates precise legal drafts within
                            seconds, incorporating suitable acts and sections
                            relevant to your needs.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        Can Juristbot provide case judgments?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                            Yes, Juristbot can provide relevant case judgments
                            from higher courts, including the Supreme Court and
                            High Courts.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        What is the cross-question feature?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      The cross-question feature helps users 
                            effective questions for cross-examining witnesses,
                            accused individuals, plaintiffs, and defendants.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        How does the scenario solver work?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      The scenario solver answers any legal questions or
                            scenarios you present, offering reliable and
                            accurate legal advice.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                      Is Juristbot suitable for non-lawyers?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      Yes, Juristbot is designed to be user-friendly and
                      accessible to anyone seeking legal information.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                       What types of legal documents can Juristbot draft?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      Juristbot can draft a variety of legal documents,
                            including contracts, petitions, affidavits, and
                            more.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                       Is Juristbot updated with the latest legal
                       information?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      Yes, Juristbot is regularly updated with the latest
                            legal information and case law.
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <div className="slider_2-body">
                      <h5
                        className="font-family"
                        style={{
                          marginBottom: "30px",
                          height: "48px",
                        }}
                      >
                        How quickly can Juristbot generate a legal draft?
                      </h5>
                      <p className="min-hgt-swipper swiper-pstyle font-family slider_2-p ">
                      Juristbot can generate a  legal draft within seconds, though it often requires human review for accuracy and compliance.
                      </p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-3 bg-light">
        <div data-aos="zoom-in-up" className="container text-center">
          <h6
            className="font-family "
            style={{
              fontWeight: "700",
              textAlign: "left",
            }}
          >
            <img
              src={absteact}
              alt="absteact"
              style={{
                height: "25px",
                marginRight: "8px",
              }}
            />
            Pricing
          </h6>
          <h5
            className="font-family "
            style={{
              fontSize: "42px",
              fontWeight: "600",
              textAlign: "left",
              marginBottom: "25px",
            }}
          >
            Pricing{" "}
            <span
              style={{
                color: "#FFD119",
              }}
            >
              Plans
            </span>
          </h5>
          <p
            className="font-family about-class"
            style={{
              textAlign: "left",
              color: "#858585",
              marginBottom: "62px",
            }}
          >
            Whether you're an individual seeking quick legal assistance or a law firm requiring comprehensive support, JuristBot offers subscription plans designed to scale with your needs. Explore our affordable options to unlock premium features, expert legal drafting, and detailed case analysis. 
            Choose the plan that fits you best and let JuristBot streamline your legal process today!
          </p>
          <div className="row d-flex">
            <div className="col-lg-7 col-sm-12 col-md-12">
              <PricingOptions onOptionChange={handleOptionChange} />
            </div>
            <div className="col-lg-5 col-sm-12 col-md-12 padding-style-price">
              <div
                className="col-12 best-membership"
                id="free-membership"
                ref={membershipRef}
                style={{ display: selectedOption === 1 ? "block" : "none" }}
              >
                <div className="col-12 mb-3 d-flex best-membership-mn">
                  <button className="base-member-btn">Free Membership</button>
                </div>
                <div
                  className="col-12 mb-3 d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    Description & Benefits
                  </p>
                  <p
                    style={{
                      color: "#A6A6A6",
                      fontSize: "15px",
                    }}
                  >
                    Our Free Membership is perfect for individuals seeking a
                    cost-effective option without compromising on quality.
                  </p>
                </div>
                <div
                  className="col-12  d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="col-12 mb-3 d-flex base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                            position: "absolute",
                            top: "-16px !important",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Unlimited scenario answers
                  </div>
                  <div
                    className="col-12 mb-3 base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Scenario wise questions
                  </div>
                  <div
                    className="col-12  base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Flexible month-to-month commitment.
                  </div>
                </div>
              </div>

              <div
                className="col-12 best-membership"
                id="basic-membership"
                style={{ display: selectedOption === 2 ? "block" : "none" }}
              >
                <div className="col-12 mb-3 d-flex best-membership-mn">
                  <button className="base-member-btn">Basic Membership</button>
                </div>
                <div
                  className="col-12 mb-3 d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                    }}
                  >
              
                    Description & Benefits
                  </p>
                  <p
                    style={{
                      color: "#A6A6A6",
                      fontSize: "15px",
                    }}
                  >
                    Our Basic Membership is perfect for individuals seeking a
                    cost-effective option without compromising on quality.
                  </p>
                </div>
                <div
                  className="col-12  d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="col-12 mb-3 d-flex base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                            position: "absolute",
                            top: "-16px !important",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Unlimited scenario answers
                  </div>
                  <div
                    className="col-12 mb-3 base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Legaldrafting Unlimited
                  </div>
                  <div
                    className="col-12 mb-3 base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                   CrossQussion Unlimited
                  </div>
                  <div
                    className="col-12  base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Relevant Judgement Unlimited
                  </div>
                 
                   
                </div>
              </div>

              <div
                className="col-12 best-membership"
                id="premium-membership"
                style={{ display: selectedOption === 3 ? "block" : "none" }}
              >
                <div className="col-12 mb-3 d-flex best-membership-mn">
                  <button className="base-member-btn">
                    Premium Membership
                  </button>
                </div>
                <div
                  className="col-12 mb-3 d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    Description & Benefits
                  </p>
                  <p
                    style={{
                      color: "#A6A6A6",
                      fontSize: "15px",
                    }}
                  >
                    Our Premium Membership is perfect for individuals seeking a
                    cost-effective option without compromising on quality.
                  </p>
                </div>
                <div
                  className="col-12  d-flex best-membership-mn"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="col-12 mb-3 d-flex base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                            position: "absolute",
                            top: "-16px !important",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Unlimited scenario answers
                  </div>
                  <div
                    className="col-12  mb-3 base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Legaldrafting Unlimited
                  </div>
                  <div
                    className="col-12 mb-3 base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                   CrossQussion Unlimited
                  </div>
                  <div
                    className="col-12  base-member-btn"
                    style={{
                      textAlign: "left",
                      fontSize: "15px",
                    }}
                  >
                    <div
                      className="checkboxes__item"
                      style={{
                        width: "12%",
                        padding: "0px",
                      }}
                    >
                      <label className="checkbox style-b">
                        <input type="checkbox" checked />
                        <div
                          className="checkbox__checkmark_1"
                          style={{
                            border: "1px solid #ffce1f",
                          }}
                          checked
                        ></div>
                      </label>
                    </div>
                    Relevant Judgement Unlimited
                  </div>
                 
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-3">
        <ContactForm data-aos="zoom-in-down" />
      </section>

      {/* Footer */}
      <footer className="text-white text-center">
        <div
          className="bg-colors"
          style={{
            borderBottom: "1px solid #323030",
            height: "auto",
          }}
        >
          <div
          
            style={{
              padding: "15px 0px",
              borderBottom: "1px solid #363333",
            }}
          >
            <img src={logoImage} style={{ width: "182px" }} alt="logo" />
          </div>
          <div
            className=" navbar-collapse"
            id="navbarNav"
            style={{
              padding: "35px 0px ",
              borderBottom: "1px solid #323030",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ul
              className="navbar-nav col-12 col-lg-6 "
              style={{
                marginLeft: "auto !important",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <li className="nav-item col-3">
                <a
                  className={`nav-link font-family ${
                    activeLink === "#home" ? "active" : ""
                  }`}
                  href="#home"
                  onClick={() => handleClick("#home")}
                  style={{
                    fontSize: "14px",
                    padding: "10px 0px",
                    color: "white",
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className={`nav-link font-family ${
                    activeLink === "#about" ? "active" : ""
                  }`}
                  href="#about"
                  onClick={() => handleClick("#about")}
                  style={{
                    fontSize: "14px",
                    padding: "10px 0px",
                    color: "white",
                  }}
                >
                  About
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className={`nav-link font-family ${
                    activeLink === "#services" ? "active" : ""
                  }`}
                  href="#services"
                  onClick={() => handleClick("#services")}
                  style={{
                    fontSize: "14px",
                    padding: "10px 0px",
                    color: "white",
                  }}
                >
                  Our services
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  className={`nav-link font-family ${
                    activeLink === "#pricing" ? "active" : ""
                  }`}
                  href="#pricing"
                  onClick={() => handleClick("#pricing")}
                  style={{
                    fontSize: "14px",
                    padding: "10px 0px",
                    color: "white",
                  }}
                >
                  Pricing
                </a>
              </li>
            </ul>
          </div>
          <div
            className="d-flex"
            style={{ padding: "15px 10px", paddingBottom: "0px", marginBottom: "15px" }}
          >
            <p
              className="footer-pvt col-4"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              @{new Date().getFullYear()} juristbot.ai All Rights Reserved.
            </p>
            <p
              className="footer-pvt col-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                
                 <Link className="footerdecoration" to="/Privacy_Policy"> Privacy Policy</Link>
            </p>
            <p
              className="footer-pvt col-4"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
             <Link className="footerdecoration" to="/Terms"> Terms & Conditions</Link>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
