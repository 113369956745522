import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import LoginForm from './components/login/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ForgetEmailForm from './components/login/forgetpassword';
import SetpasswordForm from './components/login/Setpassword';
import Navbar from './components/navbar/Navbar';
import Judgement from './components/pages/Judgement';
import RegistrationForm from './components/register/register';
import Scenario from './components/pages/Scenario';
import Legaldraft from './components/pages/LegalDrafting';
import Terms from './components/Termscondition/term';
import Help from './components/Termscondition/help';
import { Juristbotterm } from './components/Termscondition/juristbotterm';
import { Legalterm } from './components/Termscondition/legarterms';
import { CrossqusionTerms } from './components/Termscondition/Crosqussionterm';
import { Relevantterms } from './components/Termscondition/Relevanterms';
import { Tokensterms } from './components/Termscondition/Tokensandterms';
import PrivateRoute from './components/privateRoute'; 
import Subscribtion from './components/subscribtion/subscribtion';
import Authenticateotp from './components/register/authenticateotp';
import CrossQussion from './components/pages/CrossQussion';
import Earning from './components/Earning/Earning';
import ChatHistoryPage from './components/chathistory/chathistorypage';
import LandingPage from './Landingpage/Landing';
import Privicy from './components/privicy/Privicy';
import ForgetmobileForm from './components/login/ForgetpasswordMobile';
import ChatBotDemo from './components/Demo/demo';
import LegaldraftDemo from './components/Demo/draft';
import CrossDemo from './components/Demo/crossdemo';
import JudgementDemo from './components/Demo/judgementDemo';

function App() {
  const [navVisible, setNavVisible] = useState(false);
  const location = useLocation();

  const pathsWithNavbar = useMemo(() => ['/scenario', '/LegalDrafting', '/CrossQussion', '/judgement'], []);

  const shouldShowNavbar = pathsWithNavbar.includes(location.pathname);

  useEffect(() => {
    if (!pathsWithNavbar.includes(location.pathname)) {
      setNavVisible(false);
    }
  }, [location.pathname, pathsWithNavbar]);

  return (
    <>
      {shouldShowNavbar && <Navbar visible={navVisible} show={setNavVisible} />}
      <Routes>
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/scenario-solver-Demo" element={<ChatBotDemo />} />
        <Route path="/Leal-draft-Demo" element={<LegaldraftDemo/>} />
        <Route path="/Cross-Demo" element={<CrossDemo/>} />
        <Route path="/Relevent-judgement-Demo" element={<JudgementDemo/>} />
        <Route path="/" element={<LandingPage/>} />
        <Route path="/verifyotp" element={<Authenticateotp/>}/>
        <Route path="/forgetpassEmail" element={<ForgetEmailForm />} />
        <Route path="/forgetpassmobileOtp" element={<ForgetmobileForm/>} />
        <Route path="/setpassword" element={<SetpasswordForm />} />
        <Route path="/Earning" element={<PrivateRoute><Earning /></PrivateRoute>} />
        <Route path="/Subscribtion" element={<PrivateRoute><Subscribtion /></PrivateRoute>} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy_Policy" element={<Privicy/>} />
        <Route path="/help&FAQ" element={[<Help />, <Juristbotterm />, <Tokensterms />]} />
        <Route path="/help&FAQ/legaldraft" element={[<Help />, <Legalterm />, <Tokensterms />]} />
        <Route path="/help&FAQ/crossqusionterms" element={[<Help />, <CrossqusionTerms />, <Tokensterms />]} />
        <Route path="/help&FAQ/Releventterms" element={[<Help />, <Relevantterms />, <Tokensterms />]} />
        <Route 
          path="/scenario" 
          element={
            <PrivateRoute roles={['lawyer', 'public', 'judge', 'police','Advocate','chartedAccount','Auditor']}>
              <Scenario navVisible={navVisible} show={setNavVisible} />
            </PrivateRoute>
          } 
        />
        {/* <Route 
          path="/CrossQussion" 
          element={
            <PrivateRoute roles={['lawyer','Advocate','chartedAccount','Auditor']}>
              <Comminsoon navVisible={navVisible} show={setNavVisible} />
            </PrivateRoute>
          } 
        /> */}
        <Route 
          path="/LegalDrafting" 
          element={
            <PrivateRoute roles={['lawyer','Advocate','chartedAccount','Auditor']}>
              <Legaldraft navVisible={navVisible} show={setNavVisible} />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/CrossQussion" 
          element={
            <PrivateRoute roles={['lawyer','Advocate','chartedAccount','Auditor']}>
              <CrossQussion navVisible={navVisible} show={setNavVisible} />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/judgement" 
          element={
            <PrivateRoute roles={['lawyer', 'judge','Advocate','chartedAccount','Auditor']}>
              <Judgement navVisible={navVisible} show={setNavVisible} />
            </PrivateRoute>
          } 
        />
        <Route path="/chat-history/:user_id" element={<ChatHistoryPage/>} />
      </Routes>
    </>
  );
}

function MainApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default MainApp;
