import React from "react";
import "./privicy.css";
function Privicy() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-1 col-1"> </div>
          <div className="col-lg-8 col-md-10 col-10">
            <h1 className="privicy-head mt-4">Privacy Policy </h1>
            <p className="privicysm">
              Welcome to JuristBot AI This Privacy Policy explains how we
              collect, use, disclose, and safeguard your information when you
              visit our website and use our AI-based legal services at
              JuristBot.ai (the "Website"). Please read this privacy policy
              carefully. By accessing or using the Website, you agree to the
              terms of this Privacy Policy.
            </p>

            <h6 className="privicythird">1. Information We Collect</h6>
            <p className="privicysm">
              We collect various types of information in connection with the
              services we provide, including:
            </p>
            <p className="privicythird">a. Personal Information</p>
            <p className="privicysm">
              Contact Details: Name, email address, phone number, and other
              contact information. Payment Information: Billing address, payment
              card details, and transaction history. Account Information: If you
              create an account, we collect login credentials, profile details,
              and account preferences.
            </p>
            <p className="privicythird">b. Non-Personal Information</p>
            <p className="privicysm">
              Device Information: IP address, browser type, operating system,
              and device type. Usage Data:{" "}
            </p>
            <p className="privicysm">
              Pages viewed, time spent on the Website, search queries, and click
              patterns.
            </p>
            <p className="privicysm">
              Cookies and Tracking Technologies: We use cookies, web beacons,
              and similar technologies to enhance your browsing experience.
            </p>

            <h6 className="privicythird">2. How We Use Your Information</h6>
            <p className="privicysm">We use your information to:</p>
            <p className="privicysm">
              Provide and Improve Services: Deliver and personalize our legal AI
              services, respond to inquiries, and process payments.
            </p>
            <p className="privicysm">
              Communication: Send important updates, promotions, or newsletters
              if you opt-in for such communications.
            </p>
            <p className="privicysm">
              Analytics and Improvements: Analyze user behavior and trends to
              improve our website, services, and user experience.
            </p>
            <p className="privicysm">
              Legal Compliance: Comply with applicable laws, regulations, and
              legal obligations.
            </p>
            <h6 className="privicythird">3. Sharing Your Information</h6>
            <p className="privicysm">
              We do not sell or rent your personal information. However, we may
              share your data under the following circumstances:
            </p>
            <p className="privicysm">
              Service Providers: We may share your information with third-party
              vendors, consultants, and other service providers who perform
              services on our behalf.
            </p>
            <p className="privicysm">
              Legal Compliance: If required by law or legal process, we may
              disclose your data to law enforcement agencies or regulatory
              authorities.
            </p>
            <p className="privicysm">
              Business Transfers: In the event of a merger, acquisition, or
              asset sale, your data may be transferred to a new entity.
            </p>

            <h6 className="privicythird">
              4. Cookies and Tracking Technologies
            </h6>
            <p className="privicysm">
              We use cookies and similar tracking technologies to collect data
              that helps us improve our services and provide you with a better
              user experience. You can control your cookie preferences through
              your browser settings. However, disabling cookies may affect the
              functionality of the Website.
            </p>

            <h6 className="privicythird">5. Data Retention</h6>
            <p className="privicysm">
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, or as
              required by law. Once your information is no longer necessary for
              our business purposes, we will securely delete or anonymize it.
            </p>

            <h6 className="privicythird">6. Data Security</h6>
            <p className="privicysm">
              We use industry-standard security measures to protect your
              information. However, no data transmission over the Internet is
              completely secure. While we strive to protect your personal data,
              we cannot guarantee its absolute security.
            </p>

            <h6 className="privicythird">7. Your Rights</h6>
            <p className="privicysm">
              Correction: You can request that we correct any inaccurate or
              incomplete data.
            </p>
            <p className="privicysm">
              Data Portability: You can request a copy of your data in a
              machine-readable format.
            </p>
            <p className="privicysm">
              To exercise any of these rights, please contact us at
              privacy@juristbot.ai.
            </p>
            <h6 className="privicythird">8. Third-Party Links</h6>
            <p className="privicysm">
              Our Website may contain links to third-party websites that are not
              operated by us. We are not responsible for the privacy practices
              of those sites. We encourage you to review the privacy policies of
              any third-party sites you visit.
            </p>
            <h6 className="privicythird">9. Children's Privacy</h6>
            <p className="privicysm">
              Our services are not intended for children under the age of 13,
              and we do not knowingly collect information from children. If we
              learn that we have collected personal data from a child under 13,
              we will delete that information promptly.
            </p>
            <h6 className="privicythird">10. Changes to This Privacy Policy</h6>
            <p className="privicysm">
              We reserve the right to update this Privacy Policy from time to
              time. When we make changes, we will revise the "Last Updated" date
              at the top of the page. We encourage you to periodically review
              this Privacy Policy to stay informed of our data practices.
            </p>
            <h6 className="privicythird">11. Contact Us</h6>
            <p className="privicysm">
              If you have any questions or concerns about this Privacy Policy or
              how we handle your data, please contact us:
            </p>
            <p className="privicysm">
              If you have any questions or concerns about this Privacy Policy or
              how we handle your data, please contact us:
            </p>
            <p className="privicysm">JuristBot AI</p>
            <p className="privicysm">Email: info@juristbot.ai</p>
            <p className="privicysm">
              Address: Neuronest AI private limited 3G, A block, pioneer
              apartments, Avinash road, coimbatore 641 018
            </p>
          </div>
          <div className="col-lg-2 col-md-1 col-1"> </div>
        </div>
      </div>
    </div>
  );
}

export default Privicy;
