import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner,faTimes, faArrowUp, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from "axios";
import Typist from "react-typist";
import logo from "../Assets/logo-law.png";
import key from "../Assets/key.png";
import userhelp from "../Assets/user.png";
import defaultPdfImage from '../Assets/orangepdf.png'; 
import { Link } from "react-router-dom";
import config from '../config/pyconfig';
import "./dash.css";

const Legaldraft = () => {
  const userid = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [messages, setMessages] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [formattedText, setFormattedText] = useState("");
  const [helpOpen, sethelpOpen] = useState(false);
  const menuRef = useRef();
  const chatContainerRef = useRef();
  const handlehelp = () => {
    sethelpOpen(!helpOpen);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      sethelpOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSendDisabled(textInput.trim() === "" && !fileInput);
  }, [textInput, fileInput]);

  useEffect(() => {
    if (messages.length > 0) {
      setTextInput("");
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (formattedText) {
      const newMessages = [...messages];
      newMessages.push({
        sender: "bot",
        message: formattedText,
      });
      setMessages(newMessages);
      setFormattedText("");
    }
  }, [formattedText, messages]);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleDownload = async ({ filename }) => {
    try {
      setLoading(true);
  
      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZVB6D4DGP",
          secretAccessKey: "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",
        },
      });
  
      const command = new GetObjectCommand({
        Bucket: "jurist-bot",
        Key:`Draft/${filename}`,
      });
  
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
  
      const response = await axios.get(url, { responseType: "blob" });
  
      let mimeType = "";
      if (filename.endsWith(".pdf")) {
        mimeType = "application/pdf";
      } else if (filename.endsWith(".docx")) {
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else {
        throw new Error("Unsupported file type");
      }
  
      const blob = new Blob([response.data], { type: mimeType });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };
  
  const handlDownload = async ({ pdffilename }) => {
    try {
      setLoading(true);
      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZVB6D4DGP",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "jurist-bot",
        Key:`Draft_correction/${pdffilename}`,
      });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const response = await axios.get(url, { responseType: "blob" });
      let mimeType = "";
      if (pdffilename.endsWith(".pdf")) {
        mimeType = "application/pdf";
      } else if (pdffilename.endsWith(".docx")) {
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else {
        throw new Error("Unsupported file type");
      }
      const blob = new Blob([response.data], { type: mimeType });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", pdffilename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!textInput.trim() && !fileInput) {
        console.log("Please enter a message");
        return;
      }

      setLoading(true);

      const newMessages = [...messages];
      if (textInput.trim()) {
        newMessages.push({ sender: "you", message: textInput });
      }

      if (fileInput) {
        const fileNames = fileInput.name;
        newMessages.push({
          sender: "imageyou",
          message: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={defaultPdfImage} alt="PDF" className="imageyou" style={{ width: '60px', height: '60px' }} />
              <p className="mt-2">{fileNames}</p>
            </div>
          ),
        });
      }
      setMessages(newMessages);
      const formData = new FormData();
      formData.append("LegalDrafting", textInput);
      formData.append("userid", userid);
      if (fileInput) {
        formData.append("LegalDraft_pdf", fileInput);
      }
      const response = await fetch(`${config.apiBaseUrl}/law_bot`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.text) {
        setFormattedText(data.text);
      }
      if (data.doc) {
        const  filename = data.doc;
        console.log( filename)
         newMessages.push({
          sender: "bot",
          message: (
            <div cursor={{ show: false }}>
              <button className="download"  onClick={() => handleDownload({  filename })} >
                <FontAwesomeIcon icon={faDownload} /> Download
              </button>
            </div>
          ),
        });
      }
      if (data.docx) {
        const  pdffilename = data.docx;
        console.log( pdffilename)
         newMessages.push({
          sender: "bot",
          message: (
            <div cursor={{ show: false }}>
              <button className="download"  onClick={() => handlDownload({pdffilename})} >
                <FontAwesomeIcon icon={faDownload} /> Download
              </button>
            </div>
          ),
        });
      }
      setMessages(newMessages);
      setTextInput("");
      setFileInput(null);
      setPreviewImageUrl(null);
      setLoading(false);
      scrollToBottom();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  const handleKeyPress = (e) => {
    if (!isMobileDevice() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);

    if (file) {
      const imageUrl = file.type === "application/pdf" ? defaultPdfImage : URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl);
    }
  };
  const deletePreviewImage = () => {
    setFileInput(null);
    setPreviewImageUrl(null);
  };


  return (
    <div className="chat-container" ref={chatContainerRef}>
    <div className="message-containerdiv">
      {messages.length === 0 && (
        <div className="center-content">
          <img src={logo} alt="Logo" className="center-logo" />
          <p className="center-text">How can I help you?</p>
        </div>
      )}
      <div className="messages-container">
        {messages.map((message, index) => {
          if (message.sender === "bot") {
            return (
              <div key={index} className="message-container">
                <div className={`message ${message.sender}`}>
                  {typeof message.message === "string" ? (
                    <Typist avgTypingDelay={2} stdTypingDelay={3} cursor={{ show: false }}>
                      <pre> <img className="boticon" src={require("../Assets/logo-chat.png")} alt="tt" />{message.message}</pre>
                    </Typist>
                  ) : (
                    <Typist cursor={{ show: false }}>
                      {message.message}
                    </Typist>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className={`message ${message.sender}`}>
                {typeof message.message === "string" ? (
                  <div>{message.message}</div>
                ) : (
                  message.message
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
    <div className="">
      <form onSubmit={sendMessage} className="message-form">
        {previewImageUrl && (
          <div className="image-preview">
            <img src={previewImageUrl} className="image-preview-img" alt="Preview" /> 
            <FontAwesomeIcon
              icon={faTimes}
              className="delete-icon"
              onClick={deletePreviewImage}
            />
          </div>
        )}
        <textarea
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleKeyPress(e);
            }
          }}
          placeholder="Message  Legal Drafting..."
        
        ></textarea>
        <div className="file-input-container">
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*,.pdf"
            id="file-input"
          />
          <label htmlFor="file-input">
            <img src={require("../Assets/Vectoricon.png")} alt="uplode" className="icon-sent33"></img>
          </label>
        </div>
        <button
          type="submit"
          className="message-button"
          disabled={sendDisabled || loading}
        >
          {loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="icon-sent2"
              spin
            />
          ) : (
            <FontAwesomeIcon
              icon={faArrowUp}
              className="icon-sent1"
            />
          )}
        </button>
      </form>
    </div>

    <div>
      {helpOpen && (
        <div ref={menuRef} className="helpmenu">
          <Link to="/Terms" className="textecoraion">
            <h6 className="helpmenu-p">
              <img src={userhelp} alt="help" />
              <span className="help">Terms & Policies</span>
            </h6>
          </Link>
          <Link to="/help&FAQ" className="textecoraion">
            <h6 className="textkey">
              <img src={key} alt="key" />
              <span className="helpmenu-text">Help & FAQ</span>
            </h6>
          </Link>
        </div>
      )}
      <FontAwesomeIcon
        onClick={handlehelp}
        icon={faQuestion}
        className="icon-qustion fixed-bottom-left"
      />
    </div>
  </div>
);
};

export default Legaldraft;
