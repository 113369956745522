import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faArrowUp, faQuestion } from "@fortawesome/free-solid-svg-icons";
import Typist from 'react-typist';
import logo from '../Assets/logo-law.png';
import key from '../Assets/key.png';
import userhelp from '../Assets/user.png';
import defaultPdfImage from '../Assets/orangepdf.png'; 
import { Link } from "react-router-dom";
import config from '../config/pyconfig';
import "./dash.css";

const CrossQussion = () => {
  const userid = localStorage.getItem('userId');
  const [loading, setLoading] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [formattedText, setFormattedText] = useState("");
  const [helpOpen, sethelpOpen] = useState(false);
  const menuRef = useRef();
  const chatContainerRef = useRef();

  const handlehelp = () => {
    sethelpOpen(!helpOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      sethelpOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSendDisabled(textInput.trim() === "" && !fileInput);
  }, [textInput, fileInput]);

  useEffect(() => {
    if (messages.length > 0) {
      setTextInput("");
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      setPreviewImageUrl("");
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (formattedText) {
      const newMessages = [...messages];
      newMessages.push({ sender: "bot", message: formattedText, copied: false });
      setMessages(newMessages);
      setFormattedText("");
    }
  }, [formattedText, messages]);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!textInput.trim() && !fileInput) {
        console.log("Please enter a message or upload a file.");
        return;
      }
      setLoading(true);

      const newMessages = [...messages];
      if (textInput.trim()) {
        newMessages.push({ sender: "you", message: textInput, copied: false });
      }

      if (fileInput) {
        const fileName = fileInput.name;
        newMessages.push({
          sender: "imageyou",
          message: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={defaultPdfImage} alt="PDF" className="imageyou" style={{ width: '60px', height: '60px' }} />
              <p className="mt-2">{fileName}</p>
            </div>
          ),
        });
      }
    
      
      setMessages(newMessages);
      const formData = new FormData();
      formData.append("crossqestion", textInput);
      formData.append("userid", userid);
      if (fileInput) {
        formData.append("crossquestion_pdf", fileInput);
      }
      const response = await fetch(`${config.apiBaseUrl}/law_bot`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.text) {
        setFormattedText(data.text);
      }
      setMessages(newMessages);
      setTextInput("");
      setFileInput(null);
      setPreviewImageUrl(null);
      setLoading(false);
      scrollToBottom();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);

    if (file) {
      const imageUrl = file.type === "application/pdf" ? defaultPdfImage : URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl);
    }
  };

  const copyMessage = async (message, index) => {
    try {
      await navigator.clipboard.writeText(message);
      const newMessages = [...messages];
      newMessages[index].copied = true;
      setMessages(newMessages);
      setTimeout(() => {
        const resetMessages = [...messages];
        resetMessages[index].copied = false;
        setMessages(resetMessages);
      }, 2000);
    } catch (error) {
      console.error('Error copying message:', error);
    }
  };

  const deletePreviewImage = () => {
    setFileInput(null);
    setPreviewImageUrl(null);
  };
  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  const handleKeyPress = (e) => {
    if (!isMobileDevice() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };
  const shareMessageByEmail = (message) => {
    const encodedMessage = encodeURIComponent(message);
    window.location.href = `mailto:?body=${encodedMessage}`;
  };

  const handlePrint = (message) => {
    const printWindow = window.open('', '', 'width=600,height=400');
    printWindow.document.write(`<pre>${message}</pre>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    setTimeout(() => {
      printWindow.close();
    }, 1000);
  };

  return (
    <div className="chat-container" ref={chatContainerRef}>
      <div className="message-containerdiv">
        {messages.length === 0 && (
          <div className="center-content">
            <img src={logo} alt="Logo" className="center-logo" />
            <p className="center-text">How can I help you?</p>
          </div>
        )}
        <div className="messages-container">
          {messages.map((message, index) => {
            if (message.sender === "bot") {
              return (
                <div key={index} className="message-container">
                  <div className={`message ${message.sender}`}>
                    {typeof message.message === "string" ? (
                      <Typist avgTypingDelay={4} stdTypingDelay={7} cursor={{ show: false }}>
                        <pre> <img className="boticon" src={require('../Assets/logo-law.png')} alt="tt" />{message.message}</pre>
                      </Typist>
                    ) : (
                      <Typist onTypingDone={() => copyMessage(message.message, index)}>
                        {message.message}
                      </Typist>
                    )}
                    {message.copied ? (
                      <span className="material-symbols-outlined icon-copy1">
                        check
                      </span>
                    ) : (
                      <>
                        <span className="material-symbols-outlined icon-copy1"
                          onClick={() => copyMessage(message.message, index)}>content_copy</span>
                      </>
                    )}
                    <span className="material-symbols-outlined icon-share"
                      onClick={() => shareMessageByEmail(message.message)}>share</span>
                    <span className="material-symbols-outlined icon-print" onClick={() => handlePrint(message.message)}>
                      print
                    </span>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index} className={`message ${message.sender}`}>
                  {typeof message.message === "string" ? (
                    <div>{message.message}</div>
                  ) : (
                    message.message
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row  ">
        <div className="d-flex justify-content-center ">
        <form onSubmit={sendMessage} className="message-form">
          {previewImageUrl && (
            <div className="image-preview">
              <img src={previewImageUrl} className="image-preview-img" alt="Preview" /> 
              <FontAwesomeIcon
                icon={faTimes}
                className="delete-icon"
                onClick={deletePreviewImage}
              />
            </div>
          )}
          <textarea
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleKeyPress(e);
              }
            }}
            placeholder="Message Cross Q/A..."
           
          ></textarea>
          <div className="file-input-container">
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/*,.pdf"
              id="file-input"
            />
            <label htmlFor="file-input">
              <img src={require("../Assets/Vectoricon.png")} alt="uplode" className="icon-sent33"></img>
            </label>
          </div>
          <button
            type="submit"
            className="message-button"
            disabled={sendDisabled || loading}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="icon-sent2"
                spin
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowUp}
                className="icon-sent1"
              />
            )}
          </button>
        </form>
      </div>

      <div>
        {helpOpen && (
          <div ref={menuRef} className="helpmenu">
            <Link to="/Terms" className="textecoraion">
              <h6 className="helpmenu-p">
                <img src={userhelp} alt="help" />
                <span className="help">Terms & Policies</span>
              </h6>
            </Link>
            <Link to="/help&FAQ" className="textecoraion">
              <h6 className="textkey">
                <img src={key} alt="key" />
                <span className="helpmenu-text">Help & FAQ</span>
              </h6>
            </Link>
          </div>
        )}
        <FontAwesomeIcon
          onClick={handlehelp}
          icon={faQuestion}
          className="icon-qustion fixed-bottom-left"
        />
      </div>
    </div>
    </div>
    </div>
  );
};

export default CrossQussion;
