import React from "react";
import "./sub.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faIndianRupee, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
function Subscription() {
  const navigate = useNavigate();
  const closeSubscription = () => {
    navigate('/scenario'); 
  };
  return (
    <div className="subform-container">
      <div className="edit-form">
        <h6 className="x-maricon">
          <FontAwesomeIcon icon={faXmark}  onClick={closeSubscription }/>
        </h6>
        <div className="container">
          <h1 className="headtextsub">Pick a Plan that’s Right for you</h1>
          <p className="plaintextsub">
            Select from best plans, ensuring a perfect match. Need more or less?
            <br /> Customize your subscriptions for a seamless fit!
          </p>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4 col-12 pt-2">
              <div className="card firstplancard">
                <div className="card-body">
                  <button className="btn bg-light text-dark">Basic plan</button>
                  <h6 className="sub-cardtitle">
                    For all individuals and starters who
                    <br />
                    want to start with this plan
                  </h6>
                  <hr />
                  <h2 className="indanmonyicon">
                    <FontAwesomeIcon icon={faIndianRupee} />
                    499
                  </h2>
                  <h6 className="sub-carddurition">For 1 month package</h6>
                  <hr />
                  <ul className="sublist">
                  <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Scenario Wise Question</li>
                    <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Legaldrafting Unlimited</li>
                    <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Flexible month-to-month commitment. </li>
                    <li>
                      <FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Online Lawyer Assistance to clarify doubts on the above points. (TIME 10AM TO 5PM)
                    </li>
                  </ul>
                  <button className="purchasebtnsub mt-2">Purchase plan</button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 col-12 pt-2">
              <div className="card firstplancarddark">
                <div className="card-body">
                  <button className="btn bg-warning text-dark">Popular plan</button>
                  <h6 className="sub-cardpopulare">
                    For all individuals and starters who
                    <br />
                    want to start with this plan
                  </h6>
                  <hr className="text-secondary" />
                  <h2 className="indanmonyicon1">
                    <FontAwesomeIcon icon={faIndianRupee} />
                    999 
                  </h2>
                  <h6 className="sub-cardduritionpopular">For 1 month package</h6>
                  <hr className="text-secondary" />
                  <ul className="sublist text-light">
                  <li><FontAwesomeIcon className="iconsubscheck" icon={faCheckCircle}/> Scenario Wise Question</li>
                    <li><FontAwesomeIcon className="iconsubscheck" icon={faCheckCircle}/> Unlimited Legal Drafting</li>
                    <li><FontAwesomeIcon className="iconsubscheck" icon={faCheckCircle}/> Unlimited Cross Q&A</li>
                    <li><FontAwesomeIcon className="iconsubscheck" icon={faCheckCircle}/> Flexible month-to-month .</li>
                    <li>
                      <FontAwesomeIcon className="iconsubscheck" icon={faCheckCircle}/> Online Lawyer Assistance to clarify doubts on the above points. (TIME 10AM TO 5PM)
                    </li>
                  </ul>
                  <button className="purchasebtnpopular">Purchase plan</button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 col-12 pt-2">
              <div className="card firstplanpopular">
                <div className="card-body">
                  <button className="btn bg-dark text-warning">Premium plan</button>
                  <h6 className="sub-cardtitle">
                    For all individuals and starters who
                    <br />
                    want to start with this plan
                  </h6>
                  <hr />
                  <h2 className="indanmonyicon">
                    <FontAwesomeIcon icon={faIndianRupee} />
                    1499
                  </h2>
                  <h6 className="sub-carddurition">For 1 month package</h6>
                  <hr />
                  <ul className="sublist">
                  <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Scenario Wise Question</li>
                    <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Unlimited Legal Drafting</li>
                    <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Unlimited Cross Q&A</li>
                    <li><FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Unlimited Judgement Reference</li>
                    <li>
                      <FontAwesomeIcon className="iconsubscheck1" icon={faCheckCircle}/> Online Lawyer Assistance to clarify doubts on the above points. (TIME 10AM TO 5PM)
                    </li>
                  </ul>
                  <button className="purchasebtnsub">Purchase plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
