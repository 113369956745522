

import React, { useState, useEffect, useRef, useMemo } from 'react';
import './demo.css'; 
import botlos from '../Assets/logo-chat.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const CrossDemo = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  const questionsAndAnswers = useMemo(() => [
    {
      question: "Can you describe your location when you witnessed the incident?",
      answer: "I was walking my dog on the sidewalk right across from the park, about 15 feet away from the intersection."
    },
    {
      question: "How close were you to the intersection of Elm and Maple when the accident occurred?",
      answer: "I was very close; probably around 10 to 15 feet from the corner."
    },
    {
      question: "Were there any other people around you who may have also witnessed the incident?",
      answer: "Yes, there were a few other people walking in the park, but I didn’t see if they were paying attention."
    },
    {
      question: "What makes you certain that the SUV was green?",
      answer: "I remember the color clearly because it was bright and stood out against the street."
    },
    {
      question: "Can you tell us what time the incident occurred and how you know?",
      answer: "It was around 4:45 PM; I remember because I was on my way to a friend's house for a 5 PM meeting."
    },
    {
      question: "What specific actions led you to believe the SUV was speeding?",
      answer: "The SUV was going much faster than the other cars at the intersection, and it didn’t brake at all before the impact."
    },
    {
      question: "Did you observe any traffic signs at the intersection?",
      answer: "Yes, there was a stop sign clearly visible for the SUV, which it did not heed."
    },
    {
      question: "What did you see immediately after the impact?",
      answer: "The driver of the SUV jumped out of the vehicle looking frantic, and the bicyclist was on the ground not moving."
    },
    {
      question: "Did you notice the condition of the bicyclist before the accident?",
      answer: "I didn’t see the bicyclist until after the accident; they were already in the intersection when the SUV hit them."
    },
    {
      question: "Have you discussed this incident with anyone before today?",
      answer: "No, I haven’t talked about it with anyone until I was asked to provide a statement."
    },
    {
      question: "Can you describe your position when you witnessed the incident?",
      answer: "I was standing on the corner of 5th and Main, waiting to cross the street."
    },
    {
      question: "How far were you from the intersection when you saw the red sedan?",
      answer: "I was about 20 feet away from the intersection."
    },
    {
      question: "Were there any obstructions that could have affected your view of the intersection?",
      answer: "No, there were no obstructions; I had a clear view of the intersection."
    },
    {
      question: "How did you know it was a red sedan and a blue pickup truck?",
      answer: "I could clearly see the colors and makes of the vehicles as they approached the intersection."
    },
    {
      question: "What time did the incident occur, and how did you determine this time?",
      answer: "It was around 7:15 PM; I checked my watch right after the incident happened."
    },
    {
      question: "Can you explain what you mean by 'distracted'? What specific actions did you observe?",
      answer: "The driver of the sedan was looking down at their phone just before they ran the stop sign."
    },
    {
      question: "Were there any other witnesses present at the scene?",
      answer: "Yes, there were a few people walking nearby, but I didn’t talk to them."
    },
    {
      question: "Did you see any traffic signs or signals that were relevant to the situation?",
      answer: "Yes, there was a stop sign clearly visible for the red sedan."
    },
    {
      question: "After the collision, did you observe the actions of the drivers involved?",
      answer: "Yes, the driver of the sedan seemed shocked and got out of the car to check on the driver of the pickup truck."
    },
    {
      question: "Have you ever discussed this incident with anyone prior to today?",
      answer: "No, I haven't talked about it with anyone until now."
    },
    {
      question: "Can you describe where you were sitting in the café when you witnessed the incident?",
      answer: "I was sitting at a window seat on the left side of the café, facing the intersection."
    }

  ], []);


  useEffect(() => {
    const interval = setInterval(() => {
      if (currentQuestionIndex < questionsAndAnswers.length) {
        setMessages(prevMessages => {
          const newMessages = [...prevMessages, { text: questionsAndAnswers[currentQuestionIndex].question, isBot: true }];

          setLoading(true); // Start loading when a question is asked

          setTimeout(() => {
            setMessages(prevMessages => [
              ...newMessages,
              { text: questionsAndAnswers[currentQuestionIndex].answer, isBot: false }
            ]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setLoading(false); // Stop loading when the answer is displayed
          }, 2000);

          return newMessages; 
        });
      }
    }, 2100);

    return () => clearInterval(interval);
  }, [currentQuestionIndex, questionsAndAnswers]); 

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className='container-fluid'>
    <div className='row  headerchatbot '>
    <div className='col-4 col-lg-6 col-md-6 col-sm-4 '><Link to="/"><button type='button' className=' demobackbtn '><FontAwesomeIcon icon={faArrowLeft}/></button></Link></div>
    <div className=' d-flex justify-content-center col-8 col-lg-6 col-md-6 col-sm-8'><button type='button' className='btn btn-warning demobtn text-dark'>Cross Q/A Demo</button></div></div>
    <div className='chatdemo-container mt-2'>
      <div ref={chatBoxRef} className='chat-box'>
        {messages.map((message, index) => (
          <div
            key={index}
            className={message.isBot ? 'bot-message' : 'user-message'}
          >
           {message.isBot ? (
              <>
                <pre className='preS'>{message.text}</pre>
                
              </>
            ) : (
              <>
                <img src={botlos} alt="User Logo" className='user-logo' />
                <pre className='preS'>{message.text}</pre>
              </>
            )}
          </div>
        ))}
        {loading && (
          <div className="loading-indicator loadings">
            <FontAwesomeIcon icon={faEllipsis} beat />
          </div>
        )}
      </div>
    </div></div>
  );
};

export default CrossDemo;

