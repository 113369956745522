import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import emails from "../Assets/icons/email.svg";
import passwordicon from "../Assets/icons/passwordicon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import config from '../config/config';
import "./login.css";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${config.apiBaseUrl}/login`, {
        email,
        pass_word: password,
      });
      const { id, username, role } = response.data;
      toast.success("Login Successful");
      const userResponse = await axios.get(`${config.apiBaseUrl}/user/${id}`);
      
      const userData = userResponse.data.data;
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('userId', id); 
      localStorage.setItem('username', username); 
      localStorage.setItem('role', role); 

      setTimeout(() => {
        navigate("/scenario");
      }, 100);
      
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error("Network error. Try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="container-fluid  backimage2">
      <div className="row">
       <div className="col-lg-4 col-md-2 col-sm-1 col-0"></div>
        <div className="col-lg-4 col-md-8 col-sm-10 col-12 ">
            <div className="logincontainer ">
              <h4 className="logintxt">Login</h4>
              <form onSubmit={handleLogin}>
                <div className="col-12 mt-5">
                <div className="input-container">
                <img src={emails} alt="emails" className="loginfaicons" />
                  <input
                    type="email"
                    className="reg-form mt-1"
                    value={email}
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  </div>
                </div>

                <div className="password-input-container col-12 mt-3">
                <div className="input-container">
                        <img
                          src={passwordicon}
                          alt="location"
                          className="loginfaicons"
                        />
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    className="reg-form"
                    placeholder="Enter  password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  </div>
                  <span className="password-toggle" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon className="eyepassword" icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                <button
                  className="loginformbtn  mt-4"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Login"}
                </button></div>
                <p className="text-center ptextlogs">
                  Don’t have an account?
                  <Link className="colorlogin" to="/register">
                    <span className="colorlogin"> Sign up</span>
                  </Link>
                </p>
                <p className="text-center colorlogin">
                  <Link  to="/forgetpassmobileOtp" className="colornone">
                    Forgot password?
                  </Link>
                </p>
              </form>
            </div>
          </div>
   
        <div className="col-lg-4 col-md-2 col-sm-1 col-0"></div>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default LoginForm;
