import React, { useState, useEffect, useRef, useMemo } from 'react';

import axios from 'axios';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import './demo.css';
import { Link } from 'react-router-dom';
import botlos from '../Assets/logo-chat.png';

const LegaldraftDemo = () => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  const questionsAndAnswers = useMemo(() => [
    {
      question: "Draft a Anticipatory Bail Petition",
      answer:{text: "fill below details to Anticipatory Bail Petition\n1. [INSERT COURT DISTRICT]\n2. [CASE NUMBER]\n3. [YEAR]\n4. [APPLICANT’S FULL NAME]\n5. [S/O or D/O] [FATHER'S/MOTHER'S NAME]\n6. [FULL ADDRESS], [City, State, Pin Code]\n7. [STATE NAME]\n8. [INSERT FIR NUMBER]\n9. [INSERT DATE ON FIR REGISTRATION]\n10. [INSERT RELEVANT SECTIONS]\n11. [INSERT NAME OF POLICE STATION]\n12. [INSERT DISTRICT NAME]\n13. [MENTION IF ANY OTHER CASES]\n14. [DETAILS OF FAMILY MEMBERS]\n15. [INSERT PLACE WHERE THE APPLICATION BEING FILED]\n16. [INSERT DATE WHEN THE APPLICATION BEING FILED]"}
    },
    {
      question: "INSERT COURT DISTRICT: Coimbatore\nCASE NUMBER: 123\nYEAR: 2002\nAPPLICANT’S FULL NAME: Kumar.G\nS/O or D/O [FATHER'S/MOTHER'S NAME]: Gopalakrishnan\nFULL ADDRESS, [City, State, Pin Code]: 2/44, Perumal kovil street, Coimbatore.\nSTATE NAME: Tamilnadu\nINSERT FIR NUMBER: 62375678369278\nINSERT DATE ON FIR REGISTRATION: 12-03-2022\nINSERT NAME OF POLICE STATION: RS Puram station\nINSERT DISTRICT NAME: Coimbatore\nDETAILS OF FAMILY MEMBERS: Gopalakrishnan his father\nINSERT PLACE WHERE THE APPLICATION BEING FILED: 25-03-2022\nINSERT DATE WHEN THE APPLICATION BEING FILED: Coimbatore",
      answer: {
        text: "Download Anticipatory Bail Petition",
        filename: "Bail application DEMO (1).docx",
      }
    },
    {
      question: "DRAFT POWER OF ATTORNEY BY A COMPANY",
      answer:{text: "fill below details to power of Attorney\n1. [Promoter_Name]\n2. [Company_Name]\n3. [Attorney_Name]\n4. [Attorney_Company_Name]\n5. [Attorney_Office_Address]\n6. [City]\n7. [Date]\n8. [Place]\n9. [Witness1_Name]\n10. [Witness2_Name]\n11. [Attorney_Signature]"
    }},
    
    {
      question: "Promoter_Name: Kumar\nCompany_Name: ASK Private limited\nAttorney_Name: Surya\nAttorney_Company_Name: ASK Private limited\nAttorney_Office_Address: 2/44, Perur main road\nCity: Coimbatore\nDate: 12-02-2002\nPlace: Coimbatore\nWitness1_Name: Vijay\nWitness2_Name: Ganthi",
      answer: {
        text: "Download Petition Bail Draft",
        filename: "Power of attorney DEmo (1).docx",
      }
    },
    {
        question: "draft house agreement",
        answer:{text:"HOUSE RENTAL AGREEMENT\n\nThis Rental Agreement is made on [Date] by and between:\n\n1. [Landlord's Name], residing at [Landlord's Address], hereinafter referred to as the 'Landlord', which term shall, unless repugnant to the context, include his/her heirs, executors, administrators, legal representatives, and assigns of the ONE PART;\n\nAND\n\n2. [Tenant's Name], residing at [Tenant's Address], hereinafter referred to as the 'Tenant', which term shall, unless repugnant to the context, include his/her heirs, executors, administrators, legal representatives, and assigns of the OTHER PART.\n\nWHEREAS the Landlord is the absolute owner of the residential property situated at [Property Address];\n\nWHEREAS the Landlord agrees to let and the Tenant agrees to take on rent the said property for a monthly rent of [Rent Amount in figures and words];\n\nNOW THIS AGREEMENT WITNESSES AS FOLLOWS:\n\n1. The tenancy will commence on [Start Date] and end on [End Date].\n2. The Tenant agrees to pay a security deposit of [Security Deposit Amount].\n3. Rent shall be paid on or before the [Due Date] of each month.\n4. The Landlord agrees to carry out major repairs, while the Tenant is responsible for minor maintenance.\n5. Either party may terminate this agreement with a notice period of [Notice Period].\n\nIN WITNESS WHEREOF, the parties hereto have set their hands on the day, month, and year first above written.\n\n[Landlord's Signature]\n[Tenant's Signature]\n[Witness 1 Signature]\n[Witness 2 Signature]"}
      },  {
        question: "draft sale deed",
        answer:{text: "SALE DEED\n\nThis Sale Deed is made and executed on [Date] by and between:\n\n1. [Seller's Name], residing at [Seller's Address], hereinafter referred to as the 'Seller', which term shall, unless repugnant to the context, include his/her heirs, executors, administrators, legal representatives, and assigns of the ONE PART;\n\nAND\n\n2. [Buyer's Name], residing at [Buyer's Address], hereinafter referred to as the 'Buyer', which term shall, unless repugnant to the context, include his/her heirs, executors, administrators, legal representatives, and assigns of the OTHER PART.\n\nWHEREAS the Seller is the absolute and lawful owner of the property situated at [Property Address] more particularly described in Schedule 'A' hereunder;\n\nWHEREAS the Seller agrees to sell and the Buyer agrees to purchase the said property for a total sale consideration of [Amount in figures and words];\n\nNOW THIS DEED WITNESSES AS FOLLOWS:\n\n1. The Seller hereby conveys, transfers, and assigns the said property to the Buyer.\n2. The Buyer agrees to pay the said consideration in the following manner: [Payment Terms].\n3. The property is described as follows:\n   Boundaries:\n   - North: [Boundary]\n   - South: [Boundary]\n   - East: [Boundary]\n   - West: [Boundary]\n\nIN WITNESS WHEREOF, the parties hereto have set their hands on the day, month, and year first above written.\n\n[Seller's Signature]\n[Buyer's Signature]\n[Witness 1 Signature]\n[Witness 2 Signature]"}
      }
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentQuestionIndex < questionsAndAnswers.length) {
        setMessages(prevMessages => {
          const newMessages = [
            ...prevMessages,
            { text: questionsAndAnswers[currentQuestionIndex].question, isBot: true }
          ];
          setLoading(true);
          setTimeout(() => {
            setMessages(prevMessages => [
              ...newMessages,
              { text: questionsAndAnswers[currentQuestionIndex].answer.text, isBot: false, filename: questionsAndAnswers[currentQuestionIndex].answer.filename }
            ]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setLoading(false);
          }, 2300);

          return newMessages;
        });
      }
    }, 2500);

    return () => clearInterval(interval);
  }, [currentQuestionIndex, questionsAndAnswers]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleDownload = async (filename) => {
    try {
      setLoading(true);
  
      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZVB6D4DGP", 
          secretAccessKey: "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", 
        },
      });
  
      const command = new GetObjectCommand({
        Bucket: "jurist-bot",
        Key: `Drafting/${filename}`,
      });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container-fluid'>
    <div className='row  headerchatbot '>
    <div className='col-4 col-lg-6 col-md-6 col-sm-4 '><Link to="/"><button type='button' className=' demobackbtn '><FontAwesomeIcon icon={faArrowLeft}/></button></Link></div>
    <div className=' d-flex justify-content-center col-8 col-lg-6 col-md-6 col-sm-8'><button type='button' className='btn btn-warning demobtn text-dark'>Legal Drafting Demo</button></div>    </div>
    <div className='chatdemo-container mt-2'>
      <div ref={chatBoxRef} className='chat-box'>
        {messages.map((message, index) => (
          <div key={index} className={message.isBot ? 'bot-message' : 'user-message'}>
            {message.isBot ? (
              <>
                <pre className='preS'>{message.text}</pre>
                
              </>
            ) : (
              <>
                <img src={botlos} alt="User Logo" className='user-logo' />
                <pre className='preS'>{message.text}</pre>
              </>
            )}
            {message.filename && !message.isBot && (
              <button 
                onClick={() => handleDownload(message.filename)} 
                className='download-button'
               
              >
                Download Your Draft
              </button>
            )}
          </div>
        ))}
        {loading && (
          <div className="loading-indicator loadings">
            <FontAwesomeIcon icon={faEllipsis} beat />
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default LegaldraftDemo;
