import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PricingOptions = ({ onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(1);

  const handleSelect = (index) => {
    setSelectedOption(index);
    onOptionChange(index);
  };

  return (
    <div style={{ margin: "0px 10px"}}>
      <div className="row">
        <div
          className={`col-12 d-flex flex-md-row align-items-center justify-content-between price-box ${
            selectedOption === 1 ? "bg-warning" : "bg-dark"
          }`}
          onClick={() => handleSelect(1)}
        >
          <div className="col-2 font-family col-lg-1" style={{ display: "flex", justifyContent: "center"}}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedOption === 1}
                onChange={() => handleSelect(1)}
              />
            </div>
          </div>
          <div className="col-6 font-family col-lg-7">
            <p className="p-price-tag member-type text-white">
              Membership Type
            </p>
            <p className="p-price-tag member-offer text-white">
              Free Membership
            </p>
          </div>
          <div className="col-4 text-md-end text-white font-family price-data">
            <p className="price-p-data">Free</p>
          </div>
        </div>

        {/* Option 2 */}
        <div
          className={`col-12 d-flex flex-md-row align-items-center justify-content-between price-box ${
            selectedOption === 2 ? "bg-warning" : "bg-dark"
          }`}
          style={{
            marginBottom: "20px",
            border: "1px solid rgb(255 214 51)",
            padding: "20px",
            cursor: "pointer"
          }}
          onClick={() => handleSelect(2)}
        >
          <div className="col-2 font-family col-lg-1" style={{ display: "flex", justifyContent: "center"}}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedOption === 2}
                onChange={() => handleSelect(2)}
              />
            </div>
          </div>
          <div className="col-6 font-family col-lg-7">
            <p className="p-price-tag member-type text-white">
              Membership Type
            </p>
            <p className="p-price-tag member-offer text-white">
              Basic Membership
            </p>
          </div>
          <div className="col-4 text-md-end text-white font-family price-data">
            <p className="price-p-data">
              999 <span className="price-subdata">| month</span>
            </p>
          </div>
        </div>

        {/* Option 3 */}
        <div
          className={`col-12 d-flex flex-md-row align-items-center justify-content-between price-box ${
            selectedOption === 3 ? "bg-warning" : "bg-dark"
          }`}
          style={{
            marginBottom: "20px",
            border: "1px solid rgb(255 214 51)",
            padding: "20px",
            cursor: "pointer"
          }}
          onClick={() => handleSelect(3)}
        >
          <div className="col-2 font-family col-lg-1" style={{ display: "flex", justifyContent: "center"}}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedOption === 3}
                onChange={() => handleSelect(3)}
              />
            </div>
          </div>
          <div className="col-6 font-family col-lg-7">
            <p className="p-price-tag member-type text-white font-family">
              Membership Type
            </p>
            <p className="p-price-tag member-offer text-white font-family">
              Premium Membership
            </p>
          </div>
          <div className="col-4 text-md-end text-white font-family font-family price-data">
            <p className="price-p-data">
              2499 <span className="price-subdata">3 month</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingOptions;
